interface AppConfig {
  backendURL: string;
  imageServerURL: string;
  paymentGateway: {
    url: string;
    foriegn: {
      accessKey: string;
      profileId: string;
      secretKey: string;
    };
    local: {
      accessKey: string;
      profileId: string;
      secretKey: string;
    };
  };
}

interface paymentGateway {
  url: string;
  foriegn: {
    accessKey: string;
    profileId: string;
    secretKey: string;
  };
  local: {
    accessKey: string;
    profileId: string;
    secretKey: string;
  };
}

const config: AppConfig = require("./app-config.json");

export const backendURL: string = config.backendURL;
export const imageServerURL: string = config.imageServerURL;
export const paymentGatewayDetails: paymentGateway = {
  url: config.paymentGateway.url,
  foriegn: {
    accessKey: config.paymentGateway.foriegn.accessKey,
    profileId: config.paymentGateway.foriegn.profileId, 
    secretKey: config.paymentGateway.foriegn.secretKey
  },
  local: {
    accessKey: config.paymentGateway.local.accessKey,
    profileId: config.paymentGateway.local.profileId, 
    secretKey: config.paymentGateway.local.secretKey
  }
};

// // TESTING
// {
//   "backendURL": "http://192.168.1.201:9000/SaproIBEWebService/",
//   "imageServerURL": " http://192.168.1.107/ibe_image/",
//   "paymentGateway": {
//     "url": "http://192.168.1.201/web/payment_confirmation.php",
//     "foriegn": {
//       "accessKey": "e99bcc8191363764b6d926439bc02043",
//       "profileId": "DD846CD0-377D-442C-AFB5-291C0854D656",
//       "secretKey": "24ee0a1bf0124a3788a581eb0822f8318cb79c302aab43dd88c176001ee4021bd7c94729d3234b16b06388477d4892ca4b02e5ce644e40aabd76dfcfea29e85ba35c423e43364ca78c681ce0fef888a8cef9464b4caa4b7d83ba37b864cef17d88e1f3995961427db71bba35a2ab31e284db8498b8864c379f80430643478939"
//     },
//     "local": {
//       "accessKey": "e99bcc8191363764b6d926439bc02043",
//       "profileId": "DD846CD0-377D-442C-AFB5-291C0854D656",
//       "secretKey": "24ee0a1bf0124a3788a581eb0822f8318cb79c302aab43dd88c176001ee4021bd7c94729d3234b16b06388477d4892ca4b02e5ce644e40aabd76dfcfea29e85ba35c423e43364ca78c681ce0fef888a8cef9464b4caa4b7d83ba37b864cef17d88e1f3995961427db71bba35a2ab31e284db8498b8864c379f80430643478939"
//     }
//   }
// }

// PRODUCTION
// {
//   "backendURL": "https://ibebe.saprosolutions.com/SaproIBEWebService/",
//   "imageServerURL": "https://ibe.saprosolutions.com/ibe_image/",
//   "paymentGateway": {
//       "url": "https://pms.saprosolutions.com/webpay/payment_confirmation.php",
//       "foriegn": {
//           "accessKey": "bfb58c36ff033759b8d44fb40bcb3d7a",
//           "profileId": "5EE9CD43-AC06-4062-BBE4-AD4FBD9D4B18",
//           "secretKey": "24ee0a1bf0124a3788a581eb0822f8318cb79c302aab43dd88c176001ee4021bd7c94729d3234b16b06388477d4892ca4b02e5ce644e40aabd76dfcfea29e85ba35c423e43364ca78c681ce0fef888a8cef9464b4caa4b7d83ba37b864cef17d88e1f3995961427db71bba35a2ab31e284db8498b8864c379f80430643478939"
//       },
//       "local": {
//           "accessKey": "6c15562460c73a8eb041f6e4f3359d10",
//           "profileId": "D4D9EBDA-90FB-475A-9189-86C51E4C62F6",
//           "secretKey": "7f43ceca52e14a1dbbab92ec1d8b2ae1dfbbcdac05b84fe198decc2c014fbc327e36f95123c94cb88803fefde2958184818e84af6999465f951578716638d38e07a9773377484bc8807392b9da1383ae0f2edc1180c74d16b424da8cb94d6823b45523e08fa844a8a17a37695b1d8ce69d2016e52997480c9e9433c011835c23"
//       }
//   }
// }

// LINUX
// {
//   "backendURL": "https://pulse.onesapro.com:9000/SaproIBEWebService/",
//   "imageServerURL": "https://pulse.onesapro.com/IbeImage/",
//   "paymentGateway": {
//     "url": "http://192.168.1.201/web/payment_confirmation.php",
//     "foriegn": {
//       "accessKey": "e99bcc8191363764b6d926439bc02043",
//       "profileId": "DD846CD0-377D-442C-AFB5-291C0854D656",
//       "secretKey": "24ee0a1bf0124a3788a581eb0822f8318cb79c302aab43dd88c176001ee4021bd7c94729d3234b16b06388477d4892ca4b02e5ce644e40aabd76dfcfea29e85ba35c423e43364ca78c681ce0fef888a8cef9464b4caa4b7d83ba37b864cef17d88e1f3995961427db71bba35a2ab31e284db8498b8864c379f80430643478939"
//     },
//     "local": {
//       "accessKey": "e99bcc8191363764b6d926439bc02043",
//       "profileId": "DD846CD0-377D-442C-AFB5-291C0854D656",
//       "secretKey": "24ee0a1bf0124a3788a581eb0822f8318cb79c302aab43dd88c176001ee4021bd7c94729d3234b16b06388477d4892ca4b02e5ce644e40aabd76dfcfea29e85ba35c423e43364ca78c681ce0fef888a8cef9464b4caa4b7d83ba37b864cef17d88e1f3995961427db71bba35a2ab31e284db8498b8864c379f80430643478939"
//     }
//   }
// }