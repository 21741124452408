import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { imageServerURL } from "../../Config/Config";
import { SEARCH_ROOMS_TO_MAKE_RESERVATION_RESPONSE } from "traverse/response_body";

export interface SectionHeroProps {
  className?: string;
}

export interface PageHeaderDetails {
  mainTitle: string;
  titleDescription: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  const orgDetails = useSelector((state: RootState) => state.org);
  
  //props data
  const pageHeaderDetails: PageHeaderDetails[] = [
    {
      mainTitle: orgDetails.name,
      titleDescription: orgDetails.description,
    },
  ];
  
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      {pageHeaderDetails.map((details, index) => (
        <div key={index} className="flex flex-col lg:flex-row lg:items-center">
          <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">

            <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] ">
              {details.mainTitle}
            </h2>
            <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
              {details.titleDescription}
            </span>
            {/* <ButtonPrimary>Start your search</ButtonPrimary> */}
          </div>
          <div className="flex-grow">
            <img className="w-full" src={imageServerURL + orgDetails.hotelImageList.filter(image_item => image_item.imageType === "HeroSection")[0]?.imagePath} alt="hero" />
          </div>
        </div>
      ))}


      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero;
